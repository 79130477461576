/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Objednávka"}>
        <ColumnCover name={"menu"}>
          
          <Column className="--menu pb--06 pt--20" anim={null} menu={true} animS={null} border={null}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box" style={{"maxWidth":""}}>
                
                <Subtitle className="subtitle-box fs--20 w--600 swpf--uppercase lh--14 pl--0 pr--0" style={{"maxWidth":""}} content={"Čištění DPF Ostrava"}>
                </Subtitle>

                <Subtitle className="subtitle-box fs--18 subtitle-box--invert lh--1 mt--0 pl--0 pr--0" content={"<span style=\"color: rgb(213, 164, 106);\">228 227 043</span>"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/"} target={""} content={"Otázky a odpovědi"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/"} target={""} content={"Ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/kontakt"} target={""} content={"Kontakt"}>
                  </Button>

                  <Button className="btn-box btn-box--sbtn1 btn-box--pbtn3 btn-box--shape3 fs--18 w--500 swpf--uppercase lh--1 pl--08 pr--08" innerClassName="pb--10 pt--10" href={""} target={""} content={"Vyčistit filtr"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="pb--0 pt--0" name={"sluzby-1"} style={{"backgroundColor":"rgba(25, 22, 34, 1)"}}>
        </Column>


        <Column className="--center pb--50 pt--80" name={"uwhjufg46ks"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--40 pr--40 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left pb--10 pt--50" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--48 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Objednejte si čištění DPF</span>"}>
              </Title>

              <Text className="text-box text-box--center mt--20" style={{"maxWidth":668}} content={"<span style=\"color: rgb(109, 112, 134);\">Po doplnění níže uvedených informací, kontaktní údajů a odeslání nezávazné objednávky k čištění DPF vás budeme co nejdříve kontaktovat.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"do1yrc4tlch"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40">
              
              <ContactForm className="--shape2 --style2 lh--2" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Typ čištění","type":"select","options":[{"id":"hn28uzmqqrh2","value":"Pouze čištění DPF — od 5 999 Kč"},{"id":"n7lpqn3y1s49","value":"Komplexní servis DPF – od 7 990 Kč"}]},{"name":"Souhlasím se zpracovaním svých osobních údajů.","type":"checkbox","required":true},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-wwg8dr --parallax pb--80 pt--80" name={"p0zcyis97o"} style={{"backgroundColor":"rgba(25, 22, 34, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--40 pt--40" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 w--900 title-box--invert" style={{"maxWidth":910}} content={"Poptejte si čištění DPF filtru ješte dnes."}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3" href={"/objednavka"} content={"Poptat nyní"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}